@import "/src/web/styles/text";

.faceBookIcon{
  width: auto !important;
}
.kep-login-facebook{
   font-family: 'Poppins' !important;;
    background: #fff !important;;
    border: 1px solid #e45829 !important;;
    border-radius: 8px !important;
    height: 56px !important;
    margin-top: 33px !important;;
    color: #4f5270 !important;;
    font-size: 14px !important;;
    text-transform: capitalize !important;;
    padding: 0 !important;;
    font-weight: 500 !important;;
}
