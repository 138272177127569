.registration-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.register-container {
  width: 100%;
  min-height: 100vh;
  align-items: center;
}
.dashboard-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
}
.app-body {
  grid-area: body;
  min-height: 99vh;
  padding-top: 80px;
}
.fieldGroup {
  width: 100%;
  display: flex;
  .field {
    width: 100%;
    padding: 0px;
    TextArea {
      width: 100%;
    }
  }

  .textField {
    width: 100%;
    padding: 8px;
  }
  button {
    width: auto;
    width: 128px;
    height: 48px;
    margin: 0;
  }
}
.app-body-top-padding {
  padding-top: 100px;
}

.footer-form {
  .fieldGroup {
    align-items: center;
    margin-bottom: 20px;
    button {
      width: 160px;
      height: 40px;
      margin-left: 30px;
    }
    .field {
      width: 365px;
      margin: 0;
      padding: 0;

      .app-input-text {
        margin: 0;
        ::placeholder {
          color: #dedede;
          opacity: 1;
        }

        :-ms-input-placeholder {
          color: #dedede;
        }

        ::-ms-input-placeholder {
          color: #dedede;
        }
      }
    }
  }
}

.mode--Dark {
  h3,
  h6,
  a,
  label,
  .registerSection p {
    color: #fff !important;
  }
  span.account_span {
    color: #fff;
  }
  .footer__section {
    border-top: 0.5px solid #fff;
  }
}
