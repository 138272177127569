.footer {
  .fieldGroup {
    width: 38%;
    margin-left: 20%;
    margin-right: auto;
    display: flex;
    .field {
      width: 100%;
      TextArea {
        width: 100%;
      }
    }

    .textField {
      width: 100%;
      padding: 8px;
    }
  }
  border: none;
  margin-top: 1.5rem;
  padding: 0 0;
  display: flex;
  margin-left: auto;
  margin-bottom: 30px;
  > div {
    // justify-content: center;
    button {
      width: 100%;
      height: 48px;
      margin-right: 3%;
    }
  }
}
.yearBuilt > div > input {
  height: 47px;
  font-size: 14px;
  border-radius: 4px;
}
