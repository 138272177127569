@import "/src/web/styles/text";

.innerSection {
    h3 {
        font-size: 32px;
        font-weight: bold;
        color: #6f7172;
        padding-bottom: 10%;
    }
}
.body {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding-top: 20%;
}
.footer {
    margin-top: 50px;
    button {
        width: 100%;
        height: 50px;
    }
}

.inputLabel {
    color: #212529 !important;
}
