.footer {
  margin-top: 20px;
  .fieldGroup {
    width: 39.6%;
    margin-left: 19.7%;
    gap: 10px;
    margin-right: auto;
    display: flex;
    .field {
      width: 100%;
      TextArea {
        width: 100%;
      }
    }

    .textField {
      width: 100%;
      padding: 8px;
    }
  }
  border: none;
  padding: 0 8px;
  display: flex;
  margin-left: auto;
  margin-bottom: 45px;
  > div {
    // justify-content: center;
    button {
      width: 100%;
      height: 48px;
      margin-right: 3%;
    }
  }
}
.checkBox > div {
  margin-top: 20px;
}
.activeClass {
  color: #e45829;
}

.subStepsLabel {
  border-left: 1px solid #bdbdbd;
  padding-left: 19px;
  margin-left: 12px;
  margin-top: 10px;
  color: #c3c1c1;
  > div {
    position: relative;
    margin-top: 40px;
  }
}
