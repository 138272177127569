.modeImg {
  width: 100%;
}

.Mode__item {
  padding: 15px;
}

.mode--Dark {
  background-color: #393939;
  color: #fff;
}
/*.mode--Dark svg {
	color: #fff;
}*/

.AppearanceSection .registerSection {
  padding: 0;
  justify-content: center;
}

.is--active-mode .modeImg {
  border: 1px solid #e25625;
  border-radius: 5%;
}

.is--active-mode svg {
  fill: #e25625;
  color: #e25625;
}

.is--active-mode .mode--label,
.mode--Dark .is--active-mode .mode--label {
  color: #e25625;
}

.FormControlLabel {
  position: relative;
}

span.mode--label {
  position: absolute;
  bottom: 4%;
  left: 35%;
  color: #393939;
}

.mode--Dark span.mode--label {
  color: #fff;
}

.sectionMode {
  justify-content: center;
}
