@import "/src/web/styles/text";

.alertModal {
  .header {
    // padding-bottom: 15px;
    h3 {
      padding: 24px 24px 0 24px;
      margin: 0;
      color: #0a223a;
      font-weight: bold;
      text-align: center;
      font-size: 28px;
    }
    button {
      background: transparent;
      margin-left: auto;
    }
  }
  .modal-body {
    padding: 0 24px;

    h3 {
      margin: 0;
    }

    p {
      font-size: 21px;
      line-height: 1.4;
      padding-bottom: 10px;
      margin-top: 0;
    }
    .congbutton{
      display: flex;
      position: absolute !important;
      top: 87% !important;
      left: 43% !important;
    }
    Button{
      padding: 8px 34px;
    }
  }
  
  .modal-title {
    font-weight: 800;
    text-transform: uppercase;
    color: $primary-text;
    font-size: 12px !important;
    text-align: center;
    line-height: 22px;
  }
 
  .modal-footer {
    padding-top: 0;
    border-top: none;
    justify-content: center !important;
    padding-bottom:20px !important;
    button {
      width: 104px;
      height: 40px;
      margin-bottom: 11px;
    }
    .app-primary-button {
      background-color: $purple;
    }
  }

  .modal-content {
    max-height: calc(80vh - 152px);
    overflow: auto;
    color: $primary-text;
    font-size: 1.125rem;
    text-align: center;
    border: none !important;
    padding-top: 15px;
    box-shadow: none;
  }

  .app-darkblue-button {
    border: none;
    &:hover,
    &:active,
    &:focus {
      border: none;
    }
  }
}

.alert-modal {
  label {
    font-size: 1.2rem;
    cursor: pointer;
  }
  button {
    height: 48px;
  }
}

@media screen and (min-width: 1024px) {
  .alertModal {
    margin: auto;
  }
}
