@import "/src/web/styles/text";

.changePasswordModal {
  margin: 20px;
  max-width: 786px !important;
  .header {
    padding-bottom: 0;
    h3 {
      padding: 40px 24px 0 24px;
      margin: 0;
      color: #0a223a;
      font-weight: bold;
      text-align: center;
      font-size: 28px;
    }
    button {
      background: transparent;
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }

  .modal-body {
    padding: 0 24px;

    h3 {
      margin: 0;
    }

    p {
      font-size: 12px;
      line-height: 1.4;
    }
    .container {
      width: 60%;
      padding-top: 47px !important;
    }
  }

  .modal-title {
    font-weight: 800;
    text-transform: uppercase;
    color: $primary-text;
    font-size: 20px !important;
    text-align: center;
    line-height: 22px;
  }

  .modal-footer {
    padding-top: 0;
    border-top: none;
    justify-content: center !important;
    button {
      width: 100%;
      height: 50px;
    }
    .app-primary-button {
      background-color: $purple;
    }
  }

  .modal-content {
    // max-height: calc(80vh - 152px);
    height: auto;
    overflow: auto;
    color: $primary-text;
    font-size: 1.125rem;
    text-align: center;
    border: none !important;
  }

  .app-darkblue-button {
    border: none;
    &:hover,
    &:active,
    &:focus {
      border: none;
    }
  }
}

.changePasswordModal {
  label {
    font-size: 1.2rem;
    cursor: pointer;
  }
  button {
    height: 48px;
  }
}

@media screen and (min-width: 1024px) {
  .changePasswordModal {
    margin: auto;
  }
}
