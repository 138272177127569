.mode--Dark{

	.agent_text{
		color:#fff !important;
	}

	.app-input-text {
		input[type="text"], 
		input[type="password"]{
				color: #000;
				border: 2px solid #e45829;
		}
	}

	 .BoxShowingName{
		background: #fff;
	    color: #000;
	}

}