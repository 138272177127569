.app-body {
  grid-area: body;
  min-height: 99vh;
  padding-top: 80px;
}
.fieldGroup {
  width: 100%;
  display: flex;
  .field {
    width: 100%;
    padding: 0px;
    TextArea {
      width: 100%;
    }
  }

  .textField {
    width: 100%;
    padding: 8px;
  }
  button {
    width: auto;
    width: 128px;
    height: 48px;
    margin: 0;
  }
}
.app-body-top-padding {
  padding-top: 120px;
}

.footer-form {
  .fieldGroup {
    align-items: center;
    margin-bottom: 20px;
    button {
      width: 160px;
      height: 40px;
      margin-left: 30px;
    }
    .field {
      width: 365px;
      margin: 0;
      padding: 0;

      .app-input-text {
        margin: 0;
        ::placeholder {
          color: #dedede;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #dedede;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #dedede;
        }
      }
    }
  }
}


.mode--Dark{
  .navbar{
    background-color: #393939;
  }
}