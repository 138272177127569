// bg color
$bright-sky-blue: #00b3ff;
$dark: #0a223a;
$grey: #24272b;
$lightgrey: #212224;
$green: #61c5aa;
$inputBorder: #d3dfef;
$white: #ffffff;

$purple: #0047ba;
$blue: #00b3ff;

$primary-bg: #242424;
$white: #ffffff;
$black: #000000;
$dark-black: #090c12;
$lightBlue: #b7cae6;
$dotColor: rgba(255, 255, 255, 0.6);
$lightGrey: #bfbfbf;
$warmGray: #a1a1a1;
$redColor: #ef5657;
$ice-blue: #f1f6ff;

// color variables
$primary-text: $dark;
$secondary-text: $grey;
$label-color: $lightgrey;

$popover-text: $grey;

$popover-hover-text: $white;
$heading-text: $dark-black;
$blueBtn: $blue;
