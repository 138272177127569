.mode--Dark {
  background-color: #393939 !important;

  .sectionA {
    p {
      color: #fff !important;
    }
  }

  .autocomplete-dropdown-container div {
    color: #000;
  }
}
