@import "/src/web/styles/text";

.innerSection {
    h3 {
        font-size: 32px;
        font-weight: bold;
        color: #6f7172;
        padding-bottom: 10%;
    }
}
.body {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding-top: 30%;
}
.footer {
    margin-top: 50px;
    button {
        width: 100%;
        height: 50px;
    }
}
.leftSection {
    // padding: 0px 18% 0 18%;
    position: relative;
    img.headerLogo{
        top: 0;
        left: 0;
        width: 200px;
        transform: translate(0%, 15%);
        cursor: pointer;
        padding-left: 28px;
        padding-top: 7px;
    }
}
.rightSection {
    position: relative;
    img {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
.leftSection,
.rightSection {
    flex: 1;
    // height: 100vh;
}
.inputLabel {
    color: #212529 !important;
}
