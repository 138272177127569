@import "/src/web/styles/text";
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 2px;
  &:disabled,
  &[disabled],
  &.inactive {
    cursor: default;
    opacity: 0.4;
  }

  &.primary {
    background-color: #0047ba;
  }
  &.no-style {
    border: 0;
    background-color: transparent;
    color: inherit;
    padding: initial;
    -webkit-appearance: none;
  }
  &:active,
  &:focus {
    outline: none;
  }
  &.withWhiteBorder {
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #ffffff;
    color: #ffffff;
  }
  &.with-blue-border {
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #1c5bae;
    color: #1c5bae;
  }
  &.no-border {
    border: 0 !important;
  }
}

.app-default-button {
  border-color: $lightGrey;
  background-color: transparent;
  color: $grey;
}
.app-default-white-button {
  color: $blue;
  background-color: $white;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
}
.app-primary-button {
  border-color: $blue;
  background-color: $blue;
  color: $white;
}
.app-green-button {
  background-color:  #e45829;
  border-radius: 4px;
  color: $white;
  border-width: 2px;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
}
.app-orange-button {
  background-color: #e45829;
  border-radius: 8px;
  color: $white;
  width: 300px;
  border-width: 2px;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
}
.app-transparent-button {
  border: 1px solid #cacaca;
  color: #8b8ea7;
  background-color: #fff;
  border-radius: 8px;
  border-width: 1px;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
}
.app-darkblue-button {
  background-color: #0a1f30;
  border-radius: 4px;
  color: $white;
  border-width: 2px;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
}
.active {
  color: #000000;
}
.deleteRed {
  color: #e01800;
  background-color: #fbe6e4 !important;
  text-transform: uppercase;
  padding: 0;
}
.InactiveOrange {
  color: #ff0100;
  background-color: #ffe5cb !important;
  text-transform: uppercase;
  padding: 0;
}
.activeGreen {
  color: #41b100;
  background-color: #eaf6e3 !important;
  text-transform: uppercase;
  padding: 0;
}
.app-white-button {
  background-color: white;
  color: $primary-text;
  border-radius: 4px;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
}

.app-transparent-no-border-button {
  border: transparent;
  color: $blue;
  background-color: transparent;
}

.app-white-link-button {
  background: transparent;
  border: 0 !important;
  padding: 0;
  height: 30px !important;
  font-size: 14px;
  max-width: 178px !important;
}
