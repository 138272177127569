.mode--Dark .Status_text {
	color: #fff !important;
}

.frta-dg .googleAddressBar .autocomplete-dropdown-container {
	padding: 0 3px;
}
.map_fields > div {
	padding: 0px 15px;
}

.map_fields > div > input {
	font-size: 14px;
	border-radius: 4px;
	max-width: 340px;
	height: 47px;
	width: 100%;
}
.map_fields > div > label {
	margin-left: 16px;
}
