@import "/src/web/styles/text";

.innerSection {
    h3 {
        font-size: 20px;
        font-weight: bold;
        color: #6f7172;
        padding: 0 2% 3% 4px;
        img {
            width: auto;
        }
        
    }
    
    h6{
        font-size: 16px;
    color: #6F7172;
    padding: 11px 79px 31px 3px;
    }
    .headerLogo{
        padding-left:10px;
    }
}
.body {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding-top: 20%;
}
.footer {
    margin-top: 50px;
    button {
        width: 100%;
        height: 50px;
    }
}
.leftSection,
.rightSection {
    flex: 1;
}

