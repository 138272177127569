.file-input-wrapper {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 14px;
	border-radius: 3px;
	border: solid 1px #dadada;
	background-color: #ffffff;

	label {
		font-size: 1rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&.error {
		border-color: #ef5657;
	}
}
::placeholder {
	color: #000000;
	opacity: 1; /* Firefox */
	font-family: "Poppins";
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #000000;
	font-family: "Poppins";
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #000000;
	font-family: "Poppins";
}
.custom-date-piker {
	display: flex;
	Button {
		padding: 0;
	}
	.react-date-picker,
	.react-time-picker,
	.react-timerange-picker {
		width: 100%;
		.react-date-picker__wrapper,
		.react-time-picker__wrapper,
		.react-timerange-picker__wrapper {
			height: 40px;
			padding: 0 35px;
			border-radius: 4px;
			border: solid 1px #e0e0e0;
			text-align: left;
			.react-date-picker__clear-button,
			.react-time-picker__clear-button,
			.react-timerange-picker__clear-button {
				display: none;
			}
		}
	}
	.react-datepicker-wrapper {
		width: 100%;
		display: flex;
		input {
			height: 40px;
			border-radius: 4px;
			padding: 10px;
			font-size: 1rem;
			width: 100%;
			border: 1px solid #e0e0e0;
		}
	}
}

.radio-wrapper {
	display: inline-flex;
}
/* The container */
.checkbox-container {
	display: block;
	position: relative;
	padding-left: 28px;
	margin-bottom: 0;
	cursor: pointer;
	font-size: 13px !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-family: "poppins" !important;
	letter-spacing: 0.23px !important;
	color: inherit;
	font-weight: 300;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkMark {
	position: absolute;
	top: 0;
	left: 0;
	background-color: transparent;
	border-radius: 2px;
	border: solid 2px #e45829;
	height: 21px;
	width: 21px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkMark {
	background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkMark {
	background-color: #ff6008;
}

/* Create the checkMark/indicator (hidden when not checked) */
.checkMark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkMark when checked */
.checkbox-container input:checked ~ .checkMark:after {
	display: block;
}

/* Style the checkMark/indicator */
.checkbox-container .checkMark:after {
	left: 6px;
	top: 2px;
	width: 6px;
	height: 11px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	font-weight: 300;
}

// .radioField {
//   display: flex;
//   align-items: center;
//   padding: 5px 0;
//   input {
//     margin-right: 10px;
//   }
//   label {
//     margin: 0;
//   }
// }
.radioField {
	display: inline-block;
}
/* For Radio Button */
.radio-container {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: inherit;
}

.radio-label {
	color: white !important;
}
/* Hide the browser's default radio button */
.radio-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
	position: absolute;
	top: 1px;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: transparent;
	border-radius: 50%;
	border: 2px solid #ff6008;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
	background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
	background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
	top: 2px;
	left: 2px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #ff6008;
}
.custom-select__indicator {
	// margin-bottom: 10px;
	position: absolute;
	right: 0px;
	top: 11px;
	:hover {
		color: #e4582a;
	}
}

.ButtonsDateTimeTwo .react-datetime-picker__clear-button {
	padding-right: 5px !important;
}
.custom-select__control {
	height: 47px !important;
	text-align: left !important;
	top: 50%;
	font-size: 14px;

	.custom-select__single-value {
		margin-bottom: 8px;
		position: absolute;
		top: 29%;
		font-size: 14px;
	}
	.css-b8ldur-Input {
		margin-bottom: 10px !important;
		padding-bottom: 0 !important;
		padding-top: 0 !important;
	}
	.custom-select__placeholder {
		top: 45% !important;
		font-size: 14px;
	}
}
.custom-select__menu {
	text-align: left !important;
}
.custom-select-input {
	.IndicatorsContainer {
		display: none;
	}
}
.checkbox-field {
	display: flex;
	margin: 0px 0 0 0;
	align-items: center;
	color: #6f7172;
}

* {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.react-datepicker__navigation {
	width: 0 !important;
	height: 0 !important;
}
.MuiFormControl-marginNormal {
	margin: 0 !important;
	button:focus {
		outline: none !important;
		/* outline: 5px auto -webkit-focus-ring-color; */
	}
}
.MuiIconButton-root {
	border: none !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
	border: none !important;
}
.custom-file-input {
	color: transparent;
	width: 185px !important;
	height: 40px !important;
	z-index: 1 !important;
	opacity: 1 !important;
}
.custom-file-input::-webkit-file-upload-button {
	visibility: hidden;
}
.custom-file-input::before {
	content: "Upload Specification";
	color: black;
	display: inline-block;
	color: white;
	background-color: #00b3ff;
	border-radius: 3px;
	padding: 9px 32px;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	font-size: 0.875rem;
	transition: background-color 0.2s;
	font-family: poppins, sans-serif;
}
.custom-file-input:active,
.custom-file-input:focus {
	outline: 0;
}

.custom-file-input:hover::before {
	opacity: 0.8;
}

// text-area placeholder

// textarea::-webkit-input-placeholder {
//   color: #0bf;
// }

// textarea:-moz-placeholder { /* Firefox 18- */
//   color: #0bf;
// }

// textarea::-moz-placeholder {  /* Firefox 19+ */
//   color: #0bf;
// }

// textarea:-ms-input-placeholder {
//   color: #0bf;
// }

// textarea::placeholder {
//   color: #0bf;
// }
