.Maincontainer {
	height: 75vh;
	width: 100%;
	max-width: 786px;
	position: absolute;
	background-color: #fff;
	/* top: 69px;
	left: 50%; */
	-webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
	box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
	left: 50%;
	top: 29%;
	transform: translate(-50%, -20%);
}
/* .Maincontainer>div{
	height: 75vh;
	position: relative; 
	left: -50%;
} */
.container-cropper {
	height: 74%;
	padding: 10px;
	background: #fff;
	/* border: 1px solid grey; */
	-webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
	box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}

.cropper {
	height: 90%;
	position: relative;
}

.slider {
	height: 10%;
	display: flex;
	align-items: center;
	margin: auto;
	width: 60%;
}

.container-buttons {
	border: 1px solid #f5f5f5;
	height: 22%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
}
.choose-btn {
	background-color: #fff !important;
	border: 1px solid #cacaca !important;
	border-radius: 8px !important;
	margin-right: 15px !important;
	box-shadow: none !important;
	text-transform: capitalize !important;
	font-weight: 600 !important;
	padding: 7px 60px !important;
}
.choose-btn:hover {
	background-color: #eee !important;
}
.upload-btn {
	border: 1px solid #cacaca !important;
	background: #e4582a !important;
	border-radius: 8px !important;
	box-shadow: none !important;
	text-transform: capitalize !important;
	color: #fff !important;
	font-weight: 600 !important;
	padding: 7px 60px !important;
}
.upload-btn:hover {
	opacity: 0.8;
}
