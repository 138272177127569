@import "/src/web/styles/text";

.socialBtn {
  font-family: 'Poppins' !important;
  background: #fff;
  border: 1px solid #e45829 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  height: 56px !important;
  margin-top: 33px;
  color: #4f5270 !important;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 5% !important;
  -webkit-box-pack: center !important;
  opacity: 1 !important;
  cursor: pointer;
}
