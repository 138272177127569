.activeClass {
  color: #e45829;
}
.numbering {
  position: absolute;
  left: -27px;
  background: #fff;
  font-size: 14px;
  border-radius: 50%;
}

.mode--Dark {
  .MuiStepper-root {
    background: #393939;

    .numbering {
      color: red;
      padding: 6px 8px;
      height: 32px;
      width: 32px;
      left: -34px;
    }
  }
  .form_subStepsLabel__3TFQT div,
  .MuiStepLabel-label,
  .MuiButton-label {
    color: #fff;
  }

  .form_subStepsLabel__3TFQT div.activeClass {
    color: #e45829;
  }
}
.MuiStepConnector-lineVertical{
	min-height: 32px;
}