@import 'web/styles/text';

.propertyModal {
  margin: 20px;
  max-width: 786px !important;
  .error-message {
    font-size: 14px !important;
  }
  .header {
    padding-bottom: 0;
    display: flex;
    justify-content: flex-end;
    h3 {
      padding: 24px 24px 0 24px;
      margin: 0;
      color: #0a223a;
      font-weight: bold;
      text-align: center;
      font-size: 28px;
    }
    .CrossDiv {
      svg {
        margin: 20px 20px 10px 0px;
        // position: absolute;
        // float: right;
        // right: 8px;
        // top: 7px;
        cursor: pointer;
      }
    }
  }

  .modal-body {
    padding: 0 24px;

    h3 {
      margin: 0;
    }

    p {
      font-size: 21px;
      line-height: 1.4;
    }
  }

  .modal-title {
    font-weight: 800;
    text-transform: uppercase;
    color: $primary-text;
    font-size: 12px !important;
    text-align: center;
    line-height: 22px;
  }
  .modal-footer {
    padding-top: 0;
    border-top: none;
    justify-content: center !important;
    button {
      margin-top: 30px;
      max-width: 340px;
      width: 100%;
      height: 47px;
      border-radius: 5px;
      z-index: 9999999;
    }
    .app-primary-button {
      background-color: $purple;
    }
  }

  .modal-content {
    // max-height: calc(100vh - 152px);
    height: auto;
    // overflow: auto;
    color: $primary-text;
    font-size: 1.125rem;
    text-align: center;
    border: none !important;
  }

  .app-darkblue-button {
    border: none;
    &:hover,
    &:active,
    &:focus {
      border: none;
    }
  }
}

.property-modal {
  label {
    font-size: 1.2rem;
    cursor: pointer;
  }
  button {
    height: 48px;
  }
}
.fade {
  opacity: 1;
}

@media screen and (min-width: 1024px) {
  .propertyModal {
    margin: auto;
  }
}
.propertyContainer {
  display: flex;

  .mapSection {
    flex: 1;
    padding: 0;
    padding-top: 0;
  }
}
#map_section div div {
  width: 42%;
  max-height: 450px;
  border-radius: 15px;
}
#map_section .gm-iv-address {
  display: none;
}
#map_section .gmnoprint .gm-tilt {
  display: none !important;
}

#map_section div[aria-label='Street View'] {
  width: 100%;
}
.formSection {
  width: 50%;
  float: right;
  padding-top: 12px;
}
