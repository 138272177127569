@import '~bootstrap/dist/css/bootstrap.min.css';
@import './text';

@font-face {
  font-family: 'Poppins';
}
@font-face {
  font-family: 'poppins';
}

@font-face {
  font-family: 'poppinsLight';
  src: url('../assets/fonts/poppins-light-webfont.woff2') format('woff2');
}
@font-face {
  font-family: 'poppinsBold';
  src: url('../assets/fonts/poppins-bold-webfont.woff2') format('woff2');
}
@font-face {
  font-family: 'poppinsMedium';
  src: url('../assets/fonts/poppins-medium-webfont.woff2') format('woff2');
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}

.MuiSwitch-switchBase {
  color: #00b1fd !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: white !important;
}
.selectFireplace > div {
  margin-top: 15px;
}
.number_input > div > div > input {
  border-radius: 4px;
  height: 47px;
}
.slick-prev:before,
.slick-next:before {
  opacity: 1;
  color: black;
  font-size: 20px;
}
.modal-footer {
  padding: 0px;
  margin: 0px;
}
.modal-footer > * {
  margin: 0px;
  margin-bottom: 40px;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0bb4ff;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  line-height: 1.5;
  font-size: 16px;
  font-family: 'Poppins';
  scroll-behavior: smooth;
}
p {
  margin: 8px 0;
  // line-height: 1.75;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 2rem; //32px
}

h2 {
  font-size: 1.875rem; //30px
}

h3 {
  font-size: 1.75rem; //28px
}

h4 {
  font-size: 1.625rem; //26px
}

h5 {
  font-size: 1.5rem; //24px
}

h6 {
  font-size: 1.375rem; //22px
}
.pt20 {
  font-size: 1.25rem;
}
.pt18 {
  font-size: 1.125rem;
}
.pt17 {
  font-size: 1.0625rem;
}
.pt12 {
  font-size: 0.75rem;
}

.text-subdued {
  color: #808080;
}

.heading {
  text-transform: uppercase;
}

* :focus {
  outline: 0;
}
.MuiTypography-root,
.MuiInputBase-root {
  font-family: 'Poppins';
}

button:focus {
  outline: 0;
}

.closeIcon {
  padding-top: 5px;
  padding-right: 6px;
  margin: 6px 6px;
  width: 23px;
}

.redStatus {
  color: #aa2f2f;
  background-color: #d92a2a1a;
  padding: 5px;
  border-radius: 3px;
}

.orangeStatus {
  color: #db7e0e;
  background-color: #b784031a;
  padding: 5px;
  border-radius: 3px;
}
.greenStatus {
  color: #0da316;
  background-color: #2ad95a1a;
  padding: 5px;
  border-radius: 3px;
}

.MuiTooltip-arrow {
  color: #000000 !important;
}
.MuiTooltip-tooltip {
  background-color: #000000 !important;
  font-family: 'Poppins';
  > p {
    font-size: 14px;
  }
}
.MuiPickersToolbar-toolbar {
  background-color: #00b3ff !important;
}
.beds_Bath {
  display: flex;
  gap: 5%;
  width: 100%;
  .beds {
    width: 50%;
  }
  .baths {
    width: 50%;
  }
}
