@import './text';
.app-input-text {
  margin-bottom: 20px;
  position: relative;
  input {
    height: 47px;
    border-radius: 4px;
    border: solid 1px #e0e0e0;
    width: 100%;
    background: #ffffff;
    padding: 6px 14px;
  }
  textarea {
    resize: none;
    border-radius: 4px;
    border: solid 1px #e0e0e0;
    width: 100%;
    background: #ffffff;
    padding: 6px 14px;
  }
  input::-webkit-input-placeholder {
    color: #4f5270 !important;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #4f5270 !important;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #4f5270 !important;
  }

  input:-ms-input-placeholder {
    color: #4f5270 !important;
  }
}
.app-input-text input.error {
  border: 1px solid #ef5657 !important;
}
form {
  textarea {
    &.error {
      border-color: #ef5657;
    }
  }
  .section {
    padding: 8px 0;
  }

  .floating-label {
    width: auto;
  }

  .floating-label + .floating-label {
    margin: 0;
  }

  .field {
    margin-bottom: 24px;
    &.date-of-birth {
      margin-top: 4px;

      > div {
        align-items: flex-start;
      }
      .custom-datePicker {
        display: block;
      }
      .date-picker {
        display: none;
      }
      .floating-label {
        &:nth-child(2) {
          margin: 0 8px;
        }
      }
    }
  }
  textarea::-webkit-input-placeholder {
    color: #a5a5ad;
    font-size: 16px;
  }

  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a5ad;
    font-size: 16px;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a5ad;
    font-size: 16px;
  }

  textarea:-ms-input-placeholder {
    color: #a5a5ad;
    font-size: 16px;
  }

  textarea::placeholder {
    color: #a5a5ad;
    font-size: 16px;
    padding: 0px 0 0 0px;
  }
  .date-of-birth {
    padding: 0;
    .widthSet {
      width: 100%;
    }
    input {
      &:focus {
        & + span {
          color: initial !important;
          font-size: 1rem;
          top: 5px;
          padding-left: 8px;
        }
      }

      &:hover {
        border-color: #60c4aa;
      }
    }
    // Placeholder CSS
    input::-webkit-input-placeholder {
      color: #9c9c9c !important;
    }

    input:-moz-placeholder {
      /* Firefox 18- */
      color: #9c9c9c !important;
    }

    input::-moz-placeholder {
      /* Firefox 19+ */
      color: #9c9c9c !important;
    }

    input:-ms-input-placeholder {
      color: #9c9c9c !important;
    }

    .custom-select-input.error {
      color: #ef5657;
      border-color: #ef5657;
    }
  }
  label {
    font-size: 1.4rem;
    color: #b0b0b0;
  }

  .field-group.mobile {
    display: flex;

    .field {
      width: 50%;
      margin-top: 0;

      &:first-child {
        margin-right: 12px;
      }

      &:nth-child(2) {
        margin-left: 12px;
      }
    }
  }

  .floating-label {
    padding: 0;
    margin: 0;

    input {
      height: 48px;
      border-bottom: 1px solid #d3dfef;
      padding: 14px 12px;
      padding-bottom: 0;
      font-size: 18px;
      color: #24272b !important;
      padding-left: 0;
      opacity: 0.8;
      font-family: 'Poppins';

      &:focus {
        border-color: #60c4aa;
        & + span {
          color: initial !important;
          font-size: 14px;
          top: 5px;
          padding-left: 8px;
        }
      }

      &:hover {
        border-color: #60c4aa;
      }
    }

    span {
      left: 0px;
      padding: 8px 8px 0 0;
      top: 15px;
      z-index: initial;
      font-size: 1rem;
      line-height: 1;
      letter-spacing: 0.4px;
      color: #212224;
      font-family: 'museo_sans300';
    }

    &.floating {
      span {
        font-size: 16px;
        top: 4px;
        padding-left: 0;
      }
    }

    &.error {
      input {
        border-color: #ef5657;
      }
      span {
        color: #ef5657;
      }
    }
  }

  .error-message {
    position: relative;
    margin: 0;
    font-size: 14px;
    color: red;
    font-weight: 400;
    text-align: left;
  }
  .error-message2 {
    position: absolute;
    margin: 0;
    font-size: 14px;
    color: red;
    font-weight: 400;
    text-align: left;
  }

  input::-webkit-input-placeholder {
    color: #9c9c9c !important;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #9c9c9c !important;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #9c9c9c !important;
  }

  input:-ms-input-placeholder {
    color: #9c9c9c !important;
  }
  @media screen and (min-width: 1024px) {
    .field-group.three-children > .field:first-child {
      margin-right: 24px;
    }

    .field-group.mobile {
      width: 100%;
    }

    .field-group {
      display: flex;

      .field {
        width: 50%;
        margin-top: 0;
        &.date-of-birth {
          .custom-datePicker {
            display: none;
          }
          .date-picker {
            display: block;
          }
        }
        &:first-child {
          margin-right: 12px;
        }

        &:nth-child(2) {
          margin-left: 12px;
        }
      }

      &.three {
        .field {
          width: calc(100% / 3);

          &:first-child {
            margin-right: 0;
          }

          &:nth-child(2) {
            margin: 0 24px;
          }
        }
      }
    }
  }
}

.mode--Dark {
  textarea {
    color: #000;
  }
  .form_subStepsLabel__bTGmr {
    color: #fff;
  }
  .ButtonsDateTimeTwo {
    background-color: #fff;
  }
  .show-property-datetime {
    background-color: #393939;
    justify-content: space-between;

    .offerDate,
    .offerTime {
      background-color: #fff;
    }
  }
}
